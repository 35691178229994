.navbar {
  min-height: 90px;
  height: 90px;
  display: flex;
  background: #182542;
  padding: 0 2.5rem;
  border-bottom: 8px solid#137efb;

  & > div {
    display: flex;
    align-items: center;
    height: 100%;
  }

  & > .leftContainer {
    width: 50%;
    gap: 2rem;
    padding: 1.2rem 0;

    & > div {
      width: -webkit-fill-available;
      height: 100%;
      margin-right: 2.5rem;
      position: relative;
      transition: all ease-in 0.2s;

      & > input {
        height: 100%;
        width: 100%;
        border: none;
        outline: none;
        border-radius: 10px;
        padding: 0 1.5rem;
        font-weight: 650;
        // padding-left: 1.5rem;

        &::placeholder {
          color: #18254240;
        }
      }

      & > img {
        position: absolute;
        right: 60px;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #18254240;
        cursor: pointer;
        width: 1em;
        height: 1em;
        &:hover {
          color: #182542;
          background: transparent;
        }
      }

      & > svg {
        position: absolute;
        right: 25px;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #18254240;
        cursor: pointer;

        &:hover {
          color: #182542;
          background: transparent;
        }
      }

      & > :hover {
        background: #e6e5e5;
      }
    }
  }
  & > .rightContainer {
    padding: 1.2rem 1rem;
    width: 50%;
    font-weight: 600;
    color: white;
    font-size: 0.9rem;
    display: flex;
    justify-content: flex-end;

    & > .navigations {
      display: flex;
      gap: 2rem;
      margin-right: 2rem;
      height: 100%;
      align-items: center;

      & > div {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all ease-in 0.2s;

        &:hover {
          border-bottom: 1px solid white;
        }
      }

      & > :nth-child(2),
      :nth-child(3) {
        opacity: 0.4;
        pointer-events: none;
        font-weight: 500;
      }
    }

    & > .btnsContainer {
      display: flex;
      gap: 2rem;
      height: 100%;
      align-items: center;

      & > div {
        border-radius: 10px;
        cursor: pointer;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 2rem;
        transition: all ease-in 0.2s;

        &:hover {
          background: white;
          color: #182542;
          border: 1px solid #182542;
        }
      }

      & > :nth-child(1) {
        border: 1px solid #ffffff;
      }

      & > :nth-child(2) {
        background: #137efb;
        border: 0.5px solid #137efb;
      }

      & > svg {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .navbar {
    padding: 0 1rem;
    & > .leftContainer {
      width: 65%;
      padding: 1.25rem 0;
      & > div {
        display: none;
      }
    }

    & > .rightContainer {
      width: 35%;
      padding: 1.25rem 0;
      & > .navigations {
        display: none;
      }
      & > .btnsContainer {
        gap: 0.75rem;
        & > :nth-child(1) {
          display: none;
        }

        & > :nth-child(2) {
          border-radius: 5px;
        }

        & > svg {
          display: block;
          font-size: 2rem;
          color: white;
          cursor: pointer;
        }
      }
    }
  }
}
