.landingContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  height: calc(70% - 80px);

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-direction: column;
    gap: 1rem;
    // aspect-ratio: 1/1;

    & > .title {
      display: none;
      font-weight: 550;
      color: #ffffff;
      font-size: 1.25rem;
      transition: all ease-in 0.2s;
    }

    & > img {
      height: 25%;
      object-fit: contain;
    }

    &:hover > .title {
      display: block;
    }
  }

  & > :nth-child(1) {
    background: #50c7ad;
  }
  & > :nth-child(2) {
    background: #0093df;
  }
  & > :nth-child(3) {
    background: #2e85e4;
  }
  & > :nth-child(4) {
    background: #3b4859;
  }
  & > :nth-child(5) {
    background: #186ab4;
  }
  & > :nth-child(6) {
    background: #fbd10d;
  }
  & > :nth-child(7) {
    background: linear-gradient(180deg, #23defb 0%, #137efb 100%);
  }
  & > :nth-child(8) {
    background: #5795d6;
  }
}

@media screen and (max-width: 850px) {
  .landingContainer {
    height: auto;
    grid-template-columns: repeat(1, 1fr);
    background: #182542;
    padding: 0 1rem;
    gap: 1rem;

    & > div {
      // height: 150px;
      aspect-ratio: 1/1;
      border-radius: 15px;

      & > img {
        height: 25%;
      }
    }
  }
}
