.modalSessionExpired {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 5;
  display: flex;
  .overlayClose {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.85);
  }
  .modalContent {
    margin: auto;
    z-index: 1;
    width: 30%;
    max-width: 500px;
    background-color: white;
    display: flex;
    flex-direction: column;
    .head {
      height: 60px;
      background-color: $primary-color;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        filter: brightness(0) invert(1);
        height: 28px;
        margin-right: 6px;
      }
      h5 {
        margin: 0;
        line-height: 1;
        font-weight: 600;
        color: white;
        font-size: 24px;
      }
    }
    .contents {
      padding: 60px;
      .label {
        font-weight: 600;
      }
      .passwordInput {
        margin-top: 20px;
        width: 100%;
        height: 45px;
        border: 1px $primary-color;
        border-bottom-style: solid;
      }
      .btnLogin {
        cursor: pointer;
        margin-top: 20px;
        height: 45px;
        background: $primary-color;
        border-radius: 4px;
        color: white;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
