.inviteModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  z-index: 1400;
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: $overlay-color;
    z-index: 0;
  }
  .modalInvite {
    margin: auto;
    z-index: 1;
    min-height: 500px;
    width: 600px;
    background: white;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .headInvite {
      height: 100px;
      border-bottom: 1px solid $border-color;
      display: flex;
      align-items: center;
      justify-content: center;
      .headLogo {
        height: 50%;
        width: auto;
      }
    }
    .headInviteMain {
      height: 130px;
      border-bottom: none;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      .headLogo {
        height: 60%;
        width: auto;
      }
    }
    .contentInvite {
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 90px;
      min-height: 400px;
      color: $primary-color;
      &.main {
        min-height: 370px;
      }
      &.platform {
        min-height: 460px;
      }
      label {
        font-size: 18px;
        color: $primary-color;
        margin-bottom: 20px;
      }
      .links {
        height: 90px;
        display: flex;
        justify-content: space-between;
        .link {
          border: 1px solid $border-color;
          border-radius: 8px;
          flex: 0 0 29%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          @include btn-animate(white);
          img {
            width: 35px;
            height: 35px;
            object-fit: contain;
            margin-bottom: 4px;
          }
          span {
            font-size: 15px;
            font-weight: 500;
          }
          &:hover {
            background: whitesmoke;
          }
          &.true {
            border: 1px solid $primary-color;
          }
        }
      }
      .inputBox {
        height: 70px;
        width: 100%;
        border: 1px solid $border-color;
        border-radius: 15px;
        display: flex;
        position: relative;
        .dropDown {
          border-right: 1px solid $border-color;
          width: 70px;
          display: flex;
          align-items: center;
          margin: 0;
          position: relative;
          > div {
            padding: 0 10px;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              height: 35px;
              width: auto;
            }
          }
          input {
            width: 0;
            flex: 1;
            border: none;
            outline: none;
            font-size: 24px;
          }
        }
        .number {
          width: 0;
          flex: 1;
          outline: none;
          border: none;
          padding: 0 30px;
          font-size: 21px;
          -moz-appearance: textfield;
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          &[type="number"] {
            letter-spacing: 5px;
          }
          &::placeholder {
            letter-spacing: normal;
            font-size: 18px;
          }
        }
        .countryFlag {
          border-left: 1px solid $border-color;
          width: 70px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          .flag {
            object-fit: contain;
            height: 50%;
            width: 60%;
          }
        }
        .dropList {
          display: none;
        }
        &.true {
          border-radius: 15px 15px 0 0;
          .dropList {
            border: 1px none $border-color;
            border-style: none solid solid solid;
            border-radius: 0 0 15px 15px;
            background: white;
            position: absolute;
            top: calc(100% + 1px);
            left: -1px;
            right: -1px;
            height: 130px;
            display: flex;
            flex-direction: column;
            overflow: auto;
            &::-webkit-scrollbar {
              width: 0px !important;
              height: 0px !important;
            }
            &::-webkit-scrollbar-track {
              background: transparent;
            }
            .item {
              width: 100%;
              padding: 12px 30px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-bottom: 1px solid $border-color;
              img {
                height: 32px;
                width: auto;
              }
            }
          }
        }
        &.res {
          width: 80%;
          margin: 0 auto;
          margin-top: 25px;
          height: 55px;
          padding: 0 40px;
          display: flex;
          align-items: center;
          span {
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .inputBoxIn {
        height: 70px;
        width: 100%;
        border-radius: 4px;
        display: flex;
        .boxApp {
          cursor: pointer;
          border: 1px solid $border-color;
          flex: 0 0 47%;
          margin-right: 3%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 21px;
          img {
            height: 22px;
            width: 22px;
          }
          &:hover {
            background: whitesmoke;
          }
        }
      }
      .hCongrats {
        font-size: 34px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 30px;
      }
      .message {
        margin: 0 auto;
        font-size: 18px;
        width: 70%;
        text-align: center;
        text-transform: capitalize;
      }
      .downloadDesc {
        margin: 0 auto;
        flex: 0 0 90%;
        max-width: 90%;
        text-align: center;
        font-size: 21px;
        line-height: 2;
      }
    }
    .contentIndegrate {
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 40px;
      min-height: 400px;
      color: $primary-color;
      &.sb {
        justify-content: space-evenly;
      }
      label {
        font-size: 18px;
        color: $primary-color;
        margin: 30px 0 20px 0;
      }
      .inputBox {
        height: 70px;
        width: 100%;
        border: 1px solid $border-color;
        border-radius: 5px;
        display: flex;
        position: relative;
        .number {
          width: 0;
          flex: 1;
          outline: none;
          border: none;
          padding: 0 30px;
          font-size: 21px;
          -moz-appearance: textfield;
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          &[type="number"] {
            letter-spacing: 5px;
          }
          &::placeholder {
            letter-spacing: normal;
            font-size: 18px;
          }
        }
      }
      .areaBox {
        height: 210px;
        width: 100%;
        border: 1px solid $border-color;
        border-radius: 5px;
        display: flex;
        position: relative;
        .number {
          width: 0;
          flex: 1;
          outline: none;
          border: none;
          padding: 0 30px;
          font-size: 21px;
          resize: none;
          -moz-appearance: textfield;
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          &[type="number"] {
            letter-spacing: 5px;
          }
          &::placeholder {
            letter-spacing: normal;
            font-size: 18px;
          }
        }
        textarea {
          padding-top: 15px !important;
        }
      }
      .userTypeListScrl {
        height: 0 !important;
        flex: 1;
        > div {
          display: flex;
          flex-direction: column;
          padding: 4px;
          .listItem {
            height: 100px;
            flex: 0 0 100px;
            margin-bottom: 25px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid $vault-border-color;
            border-radius: 10px;
            padding: 0 30px;
            cursor: pointer;
            img {
              height: 30px;
              width: 30px;
              margin-right: 24px;
            }
            span {
              font-size: 17px;
            }
            &:hover {
              box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
            }
            &.true {
              border: 1px solid $primary-color;
              box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
            }
          }
        }
      }
    }
    .footer {
      cursor: pointer;
      background: $app-color;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      font-weight: 600;
      color: white;
      @include btn-animate(white);
    }
  }
}
