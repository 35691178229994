.mobileMenu {
  position: fixed;
  top: 90px;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  background: #182542;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;

  & > .menuOptions {
    padding: 0 0.5rem;

    & > div {
      font-size: 1.25rem;
      font-weight: 500;
      padding: 1rem 0.75rem;
      display: flex;
      //   justify-content: center;
      align-items: center;
      margin-top: 1.5rem;
      cursor: pointer;
      border-radius: 10px;
    }

    & > .selected {
      background: rgba(255, 255, 255, 0.12);
    }

    //as of now static remove once all options added
    & > :nth-child(1) {
      background: rgba(255, 255, 255, 0.12);
    }

    & > :nth-child(2),
    :nth-child(3) {
      opacity: 0.4;
      pointer-events: none;
      font-weight: 500;
    }
  }

  & > .mainBtns {
    padding: 0 1rem;

    & > div {
      font-size: 1.4rem;
      font-weight: 500;
      padding: 1rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;
      cursor: pointer;
      border-radius: 10px;
    }

    & > :nth-child(1) {
      border: 1px solid #ffffff;
    }
    & > :nth-child(2) {
      background: #137efb;
    }
  }
}
