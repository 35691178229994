.productBonds {
    /* height: 180px; */
    height: 30%;
    width: 100%;
    /* margin-top: 108px; */
    /* overflow-y: hidden; */
}

.bondsPageFirst {
    width: 100%;
    max-width: 100%;
    height: 70%;
    margin: auto;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 3rem;
    padding-top: 30px;
}

.bondsPageFirst>:first-child {
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 30px;
}

.bondsPageFirst>:last-child {
    width: 40%;
    height: 100%;
    background: url("../../assets/images/products/bondsmain.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0px 100%;
}

.bondsPageFirst>:last-child>img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform: translateY(3.5%);
}

.bondsPageFirst>:first-child>div {
    width: 85%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.bondsHeading {
    font-style: normal;
    font-weight: 600;
    font-size: calc(20px + 2vw);
    display: flex;
    align-items: center;
    color: #343C5B;
}

.bondsPara {
    font-style: normal;
    font-size: calc(10px + 0.4vw);
    line-height: 2;
    display: flex;
    align-items: center;
    color: #343C5B;
}

.bondsBtns {
    display: none;
    width: 70%;
    height: 30%;
    /* display: flex; */
    gap: 15px;
}

.bondsBtns>div {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 0.5px solid #EBEBEB;
    border-radius: 10px;
    min-height: 50px;
    max-height: 60px;
    width: 10vw;
    min-width: 190px;
    max-width: 240px;
    transition: all .5s;
    cursor: pointer;
}

.bondsBtns>div>img {
    object-fit: contain;
    max-height: 25px;
    max-width: 105px;
    min-height: 25px;
    min-width: 105px;
}

.bondsPageSecond {
    min-height: 30%;
    height: 100%;
    width: 100%;
    background: #182542;
    display: flex;
}

.bondsContentContainer {
    width: 100%;
    max-width: 100%;
    height: max-content;
    padding: 25px 3rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bondsQues {
    font-style: normal;
    font-weight: 600;
    font-size: 2.5rem;
    display: flex;
    align-items: center;
    color: white;
}

.bondsSearch {
    height: 50%;
    width: 50%;
    margin-top: 30px;
    display: flex;
    /* gap: 30px; */
    font-weight: 600;
    font-size: calc(10px + 0.6vw);
    background: #FFFFFF;
    border-radius: 10px;
}

.bondsSearch>* {
    border-radius: 0 10px 10px 0;
    /* background: white; */
    padding: 0 20px;
}

.bondsSearch>select {
    padding-right: 40px;
    /* background: white; */
    border: none;
    border-left: 0.5px solid #E5E5E5;
}

.bondsSearch input {
    /* background: white; */
    border: none;
    outline: none;
    color: rgba(21, 23, 42, 1);
    font-weight: 600;
    padding: 0px 40px;
    min-height: 70px;
    font-size: 1rem;
    border-radius: 10px 0 0 10px;
    transition: all ease-in 0.2s;
}

.bondsSearch input:hover {
    background: #e6e5e5;
}


.bondsSearch input::placeholder {
    color: rgba(21, 23, 42, 0.15);
    font-size: 1rem;
}

.bondsSearch>img {
    height: 30% !important;
    object-fit: contain;
    min-height: 20px;
    margin: auto;
    cursor: pointer;
    transition: all ease-in 0.2s;
    background: transparent;
}

.bondsSearch>img:hover {
    scale: 1.05;
}

.bondsSearch .select {
    width: max-content;
    padding: 0 2rem;
    font-weight: 600;
    font-size: 1rem;
    color: #464B4E;
    -webkit-appearance: none;
    -moz-appearance: none;
    /* background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='34' viewBox='0 0 24 24' width='34' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>"); */
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 0.5px solid #E5E5E5;
    cursor: pointer;
    transition: all ease-in 0.2s;
}

.bondsSearch .select:hover {
    border-radius: 0;
    background: #e6e5e5;
}

.bondsSearch>:first-child {
    width: 60%;
    width: -webkit-fill-available;
}

.bondsSearch .searchMobileIcon {
    display: none;
}


.bondsSearch>:last-child {
    cursor: pointer;
    padding: 0 2.5rem;
    width: max-content;
    background: #137EFB;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 1rem;
    transition: all ease-in 0.2s;
}

.bondsSearch>:last-child:hover {
    background: white;
    color: #182542;
    border-left: 0.5px solid #E5E5E5;
    /* opacity: 0.8; */
}

.rightSideMenu {
    height: 100%;
    width: 40%;
    min-width: 480px;
    max-width: 60vh;
    position: fixed;
    right: 0;
    top: 103px;
    bottom: 0;
    z-index: 2;
    /* transform: translateX(100vw); */
    transition: all ease 0.5s;
}

.rightSideMenu>:first-child {
    height: 25vh;
    position: relative;
    background: white;
    /* border-bottom: 1px solid white; */
}

.rightSideMenu>:first-child>:nth-child(1) {
    background-color: #4CAF50;
    height: 15vh;
}

.rightSideMenu>:first-child>:nth-child(2) {
    background-color: white;
    height: 10vh;
}

.rightSideMenu>:last-child {
    padding: 0 4vh;
    /* padding-top: 15vh; */
    background: white;
    height: calc(90vh - 209px);
    /* height: calc(85vh - 104.5px); */
    overflow-y: scroll;

}

.rightSideMenuInput {
    display: flex;
    flex-direction: column;
    gap: 5vh;
}

.rightSideMenuInput>div {
    display: flex;
    justify-content: space-between;
}

.rsb-bold {
    font-size: calc(15px + 0.8vh);
    font-weight: 600;
}

.bondSideValues {
    font-weight: 600;
    font-size: calc(10px + 0.4vw);
}

.bondSideDetails {
    padding-top: 5vh;
    height: 50vh;
    display: flex;
    flex-direction: column;
    gap: 5vh;
}

.bondSideDetails>div {
    padding: 3vh;
    font-weight: 600;
    font-size: calc(10px + 0.6vw);
    min-height: 50px;
    width: 100%;
    height: 20%;
    border: 0.5px solid #E7E7E7;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rightSideOverlapContainer {
    height: 15vh;
    width: 80%;
    display: flex;
    margin: auto;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
    gap: 10px;
    justify-content: space-between;
}

.rightSideOverlapContainer>div>img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 20px;
}

.rightSideOverlapContainer>:first-child {
    width: 30%;
    border-radius: 20px;
    /* padding-right: 25px; */
    /* min-height: 13vh;
    max-height: 130px; */
    background: white;
    border: 0.5px solid #E5E5E5;
    border-radius: 10px;
    padding: 20px;
}

.rightSideOverlapContainer>:nth-child(2) {
    width: 30%;
    border: 0.5px solid #E5E5E5;
    border-radius: 30px;
    height: 35%;
    background-color: white;
    padding: 15px 10px;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
}

.rightSideOverlapContainer>:nth-child(3) {
    width: 30%;
    background-color: white;
    border: 0.5px solid #E5E5E5;
    border-radius: 30px;
    height: 35%;
    padding: 15px 10px;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
}

.overlayactive {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
}


@media screen and (max-width:1500px) {
    .bondsSearch>* {
        min-height: 50px;
    }
}

@media screen and (max-width:1600px) {

    .rsb-bold {
        font-size: 15px;
    }

    .bondsPara {
        font-size: 11px;
    }

    .bondSideValues {
        font-size: 11px;
    }

    .bondSideDetails>div {
        font-size: 13px;
    }

    .rightSideOverlapContainer>:nth-child(2),
    .rightSideOverlapContainer>:nth-child(3) {
        font-size: 10px;
    }
}

@media screen and (max-width:1000px) {
    .bondsPageFirst>:last-child {
        display: none;
    }

    .bondsPageFirst>:first-child {
        width: 100%;
    }
}

@media screen and (max-width:850px) {

    .bondsQues {
        text-align: center;
    }

    .bondsBtns {
        flex-direction: column;
    }

    .productBonds {
        /* height: 100%; */
        /* margin-top: 80px; */
        /* overflow-y: scroll; */
        height: max-content;
    }

    .bondsPageFirst {
        padding-top: 0;
    }

    .bondsPageFirst>:first-child {
        padding-bottom: 0;
    }

    .bondsSearch {
        height: 70px;
        width: 100%;
        border-radius: 5px;
        margin-top: 22px;
        /* flex-direction: column; */
    }

    .bondsContentContainer {
        padding: 25px 1rem;
    }

    .bondsPageSecond {
        /* height: max-content; */
    }

    .bondsSearch .select {
        display: none;
    }

    .bondsSearch>:first-child {
        width: -webkit-fill-available;
        padding: 0 2%;
        border-radius: 5px 0 0 5px;
        height: auto;
    }

    .bondsSearch .searchMobileIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        aspect-ratio: 1/1;
        background: #137EFB;
        border-radius: 5px;
        margin: 10px;
    }

    .bondsSearch>:last-child {
        display: none;
        width: 15%;
        min-width: 100px;
        border-radius: 0 5px 5px 0;
    }

}

@media screen and (max-width:550px) {
    .bondsPageFirst {
        height: max-content;
        padding: 20px 0;
    }

    .bondsSearch>* {
        min-height: 40px;
    }

    .bondsSearch input {
        min-height: 40px;
        font-size: 0.8rem;
    }

    .bondsSearch input::placeholder {
        font-size: 0.8rem;
        font-weight: 550;
    }

    .bondsSearch>:first-child {
        font-size: 0.8rem;
        font-weight: 550;
    }

    .bondsSearch>:last-child {
        font-size: 0.8rem;
        font-weight: 550;
    }
}