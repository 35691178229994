.toolTipBox {
  top: 105%;
  width: 240px;
  display: none;
  flex-direction: column;
  position: absolute;
  left: 0;
  background: white;
  border: 1px solid $border-color;
  padding: 8px;
  .title {
    font-size: 16px;
    font-weight: 600;
  }
  p {
    margin-top: 10px;
    font-size: 15px;
    line-height: 1.5;
    margin-bottom: 0;
  }
  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid $border-color;
    top: -6px;
    left: 3px;
  }
  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid white;
    top: -5px;
    left: 4px;
  }
}
.trustDetailWrapper {
  flex: 1;
  display: flex;
  .trustDetail {
    padding: 50px 90px 0 90px;
    flex: 1;
    display: flex;
    flex-direction: column;
    .headProfile {
      display: flex;
      align-items: center;
      .dp {
        height: 70px;
        width: auto;
        margin-right: 6px;
      }
      .texts {
        flex: 1;
        .headMain,
        .headSub {
          display: flex;
          line-height: 1.2;
          align-items: center;
        }
        .headMain {
          height: 35px;
          font-size: 30px;
          font-weight: 600;
          .calculator {
            margin-left: 8px;
            display: flex;
            height: 100%;
            width: fit-content;
            align-items: center;
            position: relative;
            img {
              height: 80%;
              width: auto;
            }
            .toolTipCalculator {
              display: none;
              position: absolute;
              top: 105%;
              left: 0;
              font-size: 12px;
              background: white;
              white-space: nowrap;
              border: 1px solid $border-color;
              padding: 4px 8px;
              &::before {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-bottom: 6px solid $border-color;
                top: -6px;
                left: 3px;
              }
              &::after {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-bottom: 5px solid white;
                top: -5px;
                left: 4px;
              }
            }
            &:hover {
              .toolTipCalculator {
                display: block;
              }
            }
          }
        }
        .headSub {
          font-size: 20px;
          font-weight: 600;
        }
      }
      .buttonsGroup {
        display: flex;
        height: 60px;

        .btnAction,
        .btcValue {
          font-weight: 600;
          width: 130px;
          border: 1px solid $primary-color;
          display: flex;
          color: $primary-color;
          flex-direction: column;
          text-align: center;
          .head {
            cursor: pointer;
            font-weight: 500;
            background: $primary-color;
            color: white;
            font-size: 14px;
          }
          .val {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .btcValue {
          border-right: none;
          width: 180px;
          .val {
            .native {
              display: block;
            }
            .usd {
              display: none;
            }
            &:hover {
              .native {
                display: none;
              }
              .usd {
                display: flex;
              }
            }
          }
        }
      }
    }
    .intro {
      margin-top: 40px;
      display: flex;
      .textDesc {
        font-size: 17px;
      }
    }
    .blanceNappSwitcher {
      margin-top: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .allAppBalance {
        position: relative;
        height: 100%;
        line-height: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .allAppText {
          font-size: 16px;
        }
        .balanceAmt {
          font-size: 23px;
          font-weight: 600;
        }
        &:hover {
          .toolTipBox {
            display: flex;
          }
        }
      }
      .appsGroup {
        display: flex;
        .appItm {
          height: 45px;
          width: 45px;
          margin-left: 10px;
          border-radius: 6px;
          border: 1px solid $border-color;
          overflow: hidden;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
          &:hover {
            border-color: $primary-color;
            filter: brightness(0.8);
          }
        }
        .search-wrapper {
          margin-left: 15px;
          height: 45px;
          width: 45px;
          transition: all 0.4s ease;
          position: relative;
          .serch-n-result {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 1;
            transition: all 0.4s ease;
            border-radius: 6px;
            min-height: 45px;
            max-height: 360px;
            overflow: auto;
            background: rgba(216, 216, 216, 0.5);
            display: flex;
            flex-direction: column;
            .search-icon {
              margin: 10px;
              width: 25px;
              height: 25px;
              cursor: pointer;
            }
            .search {
              height: 45px;
              display: flex;
              justify-content: center;
              align-items: center;
              position: sticky;
              top: 0;
              background: white;
              input {
                border: none;
                background: none;
                height: 100%;
                width: 0;
                flex-grow: 1;
                font-size: 20px;
                padding: 0 20px;
              }
              .serch-close {
                color: $primary-color;
                cursor: pointer;
                padding: 0 14px 0 0;
                height: 100%;
                font-size: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
            .search-res {
              padding: 0 25px;
              height: 56px;
              min-height: 56px;
              border-top: 1px solid #f2f2f2;
              display: flex;
              align-items: center;
              cursor: pointer;
              .search-coin {
                width: 30px;
                height: 30px;
              }
              .coin,
              .value {
                font-size: 18px;
                font-weight: 600;
              }
              .coin {
                margin-left: 10px;
              }
              .value {
                margin-left: auto;
              }
              .change {
                margin-left: 5px;
                font-size: 10px;
                color: $primary-green;
                &.true {
                  color: #e50914;
                }
              }
            }
          }
          &.true {
            width: 350px;
            .serch-n-result {
              background-color: white;
              border: 1px solid rgba(70, 75, 78, 0.5);
              width: 350px;
            }
          }
        }
      }
    }
    .earningTierBox {
      margin-top: 20px;
      height: 90px;
      border: 1px solid $border-color;
      display: flex;
      .label,
      .valuesPercent {
        border-right: 1px solid $border-color;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        font-size: 19px;
        .percent,
        .amount {
          position: relative;
          &:hover {
            .toolTipBox {
              display: flex;
              z-index: 2;
            }
          }
        }
        &:last-child {
          border: none;
          .percent,
          .amount {
            flex: 1;
            position: relative;
          }
          .percent {
            text-align: center;
          }
          .amount {
            text-align: right;
            padding-right: 50px;
          }
        }
      }
      .label {
        cursor: pointer;
        &.earnings {
          flex: 18%;
        }
        &.tier {
          flex: 12%;
        }
        &:hover {
          font-weight: 500;
        }
      }
      .valuesPercent {
        flex: 0 0 35%;
        .percent {
          color: #3ea154;
          font-weight: 500;
        }
        .amount {
          font-weight: 600;
        }
      }
    }
    .earningsController,
    .tierComparison {
      margin-top: 20px;
      border: 1px solid $border-color;
      padding: 30px;
      .ecTitle {
        font-size: 23px;
        font-weight: 600;
      }
      .timeRow,
      .tierRow {
        margin-top: 15px;
        height: 50px;
        display: flex;
        .rowLbl {
          flex: 0 0 17%;
          display: flex;
          align-items: center;
        }
        .optListWpapper {
          width: 0 !important;
          flex: 1;
          .optList {
            display: flex;
            align-items: center;
            .optItm {
              white-space: nowrap;
              cursor: pointer;
              height: 80%;
              padding: 0 30px;
              border: 1px solid $border-color;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 15px;
              &.true {
                border: 2px solid $primary-color;
              }
            }
          }
        }
      }
      .btnSave {
        cursor: pointer;
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $primary-color;
        font-size: 20px;
        color: white;
        margin-left: auto;
        height: 40px;
        width: 120px;
      }
    }
    .tableHead {
      margin-top: 50px;
      margin-bottom: 10px;
      height: 30px;
      background-color: $primary-color;
      padding: 0 50px;
      display: flex;
      align-items: center;
      color: white;
      position: relative;
      font-size: 14px;
      .asset,
      .holding,
      .rate,
      .payout {
        position: relative;
        .toolTipCalculator {
          z-index: 2;
          display: none;
          position: absolute;
          top: 105%;
          left: 0;
          font-size: 12px;
          background: white;
          color: $primary-color;
          white-space: nowrap;
          border: 1px solid $border-color;
          padding: 4px 8px;
          &::before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 6px solid $border-color;
            top: -6px;
            left: 3px;
          }
          &::after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid white;
            top: -5px;
            left: 4px;
          }
        }
        &:hover {
          .toolTipCalculator {
            display: block;
          }
        }
      }
      .asset {
        flex: 0 0 30%;
      }
      .holding,
      .rate,
      .payout {
        flex: 0 0 18%;
      }
      .rate {
        flex: 0 0 15%;
        text-align: center;
        .toolTipCalculator {
          left: 45%;
        }
      }
      .payout {
        flex: 0 0 21%;
        text-align: right;
        .toolTipCalculator {
          left: 80%;
        }
      }
      .searchIcon {
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      .searchBar {
        z-index: 2;
        border: 1px solid $border-color;
        position: absolute;
        top: 110%;
        right: 10px;
        background: white;
        padding: 2px;
        border-radius: 3px;
      }
    }
    .coinListWrapper {
      height: 0 !important;
      flex: 1;
      .coinList {
        display: flex;
        flex-direction: column;
        padding-right: 2px;
        .coinItem {
          margin: 10px 0;
          border: 1px solid $border-color;
          height: 110px;
          flex: 0 0 110px;
          padding: 0 45px;
          display: flex;
          display: flex;
          align-items: center;
          .coin {
            flex: 0 0 30%;
            display: flex;
            align-items: center;
            font-size: 20px;
            font-weight: 600;
            .logo {
              object-fit: contain;
              height: 24px;
              width: auto;
              margin-right: 6px;
            }
          }
          .balance,
          .percent,
          .total {
            flex: 0 0 18%;
          }
          .percent {
            flex: 0 0 15%;
            color: #3ea154;
            text-align: center;
          }
          .total {
            flex: 0 0 21%;
            font-weight: 600;
            text-align: right;
          }
          &:hover {
            cursor: pointer;
            background: #f5f5f5;
          }
        }
      }
    }
  }
  .sideLevels {
    flex: 0 0 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    .hexa {
      width: 45px;
      height: 45px;
      opacity: 0.5;
      &.true {
        zoom: 1.3;
        opacity: 1;
        font-weight: 500;
      }
    }
  }
}
