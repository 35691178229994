.icedIndex {
  flex: 1;
  display: flex;
  flex-direction: column;
  // .tab {
  //   height: 70px;
  //   display: flex;
  //   .tab-itm {
  //     cursor: pointer;
  //     flex: 1;
  //     border-bottom: 2px solid #e7e7e7;
  //     font-size: 19px;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     &.true {
  //       border-color: $primary-color;
  //     }
  //   }
  // }
  .contentSectionMobile {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0;
    .earn-intrest {
      flex: 1;
      display: flex;
      flex-direction: column;
      .earn-intrest-controll {
        padding: 20px 40px;
        width: 100%;
        height: 110px;
        display: flex;
        justify-content: space-between;
        overflow-x: auto;
        &::after {
          content: "";
          min-width: 20px;
        }
        .tab-item {
          flex: 0 0 90%;
          opacity: 0.35;
          cursor: pointer;
          display: flex;
          height: 100%;
          background-color: white;
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.08);
          padding: 0 15px;
          align-items: center;
          justify-content: flex-start;
          border: 1px solid #e7e7e7;
          margin-right: 10px;

          .coin-img {
            height: 30px;
            width: 30px;
          }
          .text-content {
            margin-left: 10px;
            h5 {
              line-height: 1;
              font-size: 20px;
              margin: 0;
              color: $primary-color;
              font-weight: 600;
            }
            h6 {
              display: none;
            }
          }
          &.true {
            opacity: 1;
          }
        }
      }
      .assetPlatformTable {
        border: 1px solid #e7e7e7;
        flex: 1;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        .assetTableControlls {
          height: 40px;
          border-bottom: 1px solid #e7e7e7;
          display: flex;
          .bt-asset {
            padding: 0 10px;
            cursor: pointer;
            border-right: 1px solid #e7e7e7;
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 100px;
            font-size: 14px;
            color: $primary-color;
            opacity: 0.7;
            .platform-select {
              display: flex;
              position: relative;
              padding: 5px;
              justify-content: space-between;
              align-items: center;
              border: 1px solid #e7e7e7;
              min-width: 170px;
              margin-left: 10px;
              font-weight: normal;
              .btn-togle {
                display: flex;
                align-items: center;
                font-size: 13px;
                font-weight: 600;
                img {
                  height: 14px;
                  width: 14px;
                  margin-right: 4px;
                }
              }
              .platform {
                font-size: 11px;
                margin-left: auto;
                margin-right: 4px;
              }
              .menu {
                border: 1px solid #e7e7e7;
                z-index: 222;
                position: absolute;
                top: 100%;
                left: -1px;
                right: -1px;
                .btn-togle {
                  background-color: white;
                  border-bottom: 1px solid #e7e7e7;
                  padding: 5px;
                  display: flex;
                  align-items: center;
                  font-size: 11px;
                  img {
                    height: 14px;
                    width: 14px;
                    margin-right: 12px;
                  }
                  .platform {
                    font-size: 11px;
                    margin-left: auto;
                    margin-right: 14px;
                  }
                  &:last-child {
                    border: none;
                  }
                  &:hover {
                    background-color: rgb(246, 246, 246);
                  }
                }
              }
            }
            &.true {
              opacity: 1;
              font-weight: 600;
            }
          }
        }
        .table {
          display: flex;
          flex-direction: column;
          .tableHead {
            display: flex;
            background: #464b4e;
            justify-content: space-between;
            padding: 4px 20px;
            font-size: 13px;
            color: white;
            font-weight: 500;
          }
          .tableRow {
            display: flex;
            background: white;
            justify-content: space-between;
            padding: 0 30px 0 20px;
            color: $primary-color;
            font-size: 18px;
            font-weight: 600;
            height: 80px;
            border: 1px solid #f2f2f2;
            align-items: center;
          }
        }
      }
    }
  }
}
