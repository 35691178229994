.vaults {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 60px 50px 40px 80px;
  .head {
    height: 60px;
    display: flex;
    margin-bottom: 4rem;
    .vault-n-balance {
      color: $primary-color;
      line-height: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .vault {
        font-size: 17px;
      }
      .balance {
        font-size: 37px;
        font-weight: 600;
        small {
          cursor: pointer;
          font-size: 22px;
        }
      }
    }
    .coin-select {
      display: flex;
      margin-left: auto;
      .coin-wrap {
        height: 60px;
        width: 60px;
        border-radius: 6px;
        border: 1px solid $vault-border-color;
        margin-left: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 40px;
          height: 40px;
        }
        &.true {
          background-color: $primary-color;
          img {
            filter: brightness(0) invert(1);
          }
        }
      }
      .search-wrapper {
        margin-left: 15px;
        height: 60px;
        width: 60px;
        transition: all 0.4s ease;
        position: relative;
        .serch-n-result {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          z-index: 1;
          transition: all 0.4s ease;
          border-radius: 6px;
          min-height: 60px;
          max-height: 360px;
          overflow: auto;
          background: rgba(216, 216, 216, 0.5);
          display: flex;
          flex-direction: column;
          .search-icon {
            margin: 15px;
            width: 30px;
            height: 30px;
            cursor: pointer;
          }
          .search {
            height: 56px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: sticky;
            top: 0;
            background: white;
            input {
              border: none;
              background: none;
              height: 100%;
              width: 0;
              flex-grow: 1;
              font-size: 20px;
              padding: 0 20px;
            }
            .serch-close {
              color: $primary-color;
              cursor: pointer;
              padding: 0 14px 0 0;
              height: 100%;
              font-size: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          .search-res {
            padding: 0 25px;
            height: 56px;
            min-height: 56px;
            border-top: 1px solid #f2f2f2;
            display: flex;
            align-items: center;
            cursor: pointer;
            .search-coin {
              width: 30px;
              height: 30px;
            }
            .coin,
            .value {
              font-size: 18px;
              font-weight: 600;
            }
            .coin {
              margin-left: 10px;
            }
            .value {
              margin-left: auto;
            }
            .change {
              margin-left: 5px;
              font-size: 10px;
              color: $primary-green;
              &.true {
                color: #e50914;
              }
            }
          }
        }
        &.true {
          width: 350px;
          .serch-n-result {
            background-color: white;
            border: 1px solid rgba(70, 75, 78, 0.5);
            width: 350px;
          }
        }
      }
    }
  }
  .controlls {
    height: 40px;
    min-height: 40px;
    display: flex;
    .drop-select,
    .search {
      position: relative;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      border: 1px solid $vault-border-color;
      color: $primary-color;
      border-radius: 6px;
      .content {
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &.transaction {
          width: 230px;
        }
        &.direction {
          width: 140px;
        }
      }
      .menu {
        z-index: 5;
        position: absolute;
        top: calc(100% - 2px);
        margin-left: -2px;
        margin-right: -2px;
        padding-top: 10px;
        padding-bottom: 10px;
        left: 0;
        right: 0;
        border-radius: 0 0 6px 6px;
        border: 1px solid $vault-border-color;
        border-top: none;
        background-color: white;
        display: flex;
        flex-direction: column;
        .menuItem {
          cursor: pointer;
          height: 39px;
          display: flex;
          padding: 0 22px;
          align-items: center;
          &:hover {
            transform: scale(1.02);
          }
        }
      }
      &.liquid {
        border-radius: 6px 0 0 6px;
        border-right: none;
      }
      &.bond {
        border-radius: 0 6px 6px 0;
        border-left: none;
      }
    }
    .drop-select {
      cursor: pointer;
      &.true {
        background: $primary-color;
        color: white;
      }
    }
    .search {
      input {
        height: 40px;
        width: 200px;
        border: none;
        background: none;
      }
    }
  }
  .assetApps {
    margin-top: 20px;
    width: 100%;
    flex: 0 0 110px;
    height: 110px !important;
    .view {
      display: flex;
      padding: 4px 0;
      padding-bottom: 50px;
      .appIcon {
        position: relative;
        height: 100%;
        min-width: 60px;
        max-width: 60px;
        border: 1px solid #e7e7e7;
        border-radius: 8px;
        margin-right: 15px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        font-weight: 600;
        color: $primary-color;
        img {
          width: 100%;
          height: 100%;
        }
        .appTooltip {
          display: none;
          position: absolute;
          min-width: 70px;
          height: 26px;
          justify-content: center;
          align-items: center;
          font-size: 10px;
          padding: 0 8px;
          top: calc(100% + 10px);
          background: white;
          border: 1px solid $vault-border-color;
          &::before {
            content: "";
            position: absolute;
            top: -9px;
            width: 0;
            height: 0;
            border-left: 9px solid transparent;
            border-right: 9px solid transparent;
            border-bottom: 9px solid $vault-border-color;
          }
          &::after {
            content: "";
            position: absolute;
            top: -8px;
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid white;
          }
        }
        &:hover {
          .appTooltip {
            display: flex;
          }
        }
        &.true {
          border-color: $primary-color;
        }
      }
    }
  }
  .vaults-list {
    margin-top: 30px;
    .vaultsView {
      .day {
        margin-top: 1rem;
        font-size: 16px;
        line-height: 1;
        padding: 6px 0;
        font-weight: 600;
      }
      .vaults-itm {
        display: flex;
        margin-left: 20px;
        height: 90px;
        img {
          background: #ffeae9;
          height: 40px;
          width: 40px;
          border-radius: 20px;
          margin: auto 20px;
        }
        .name-n-date {
          height: 100%;
          display: flex;
          line-height: 1;
          flex-direction: column;
          justify-content: center;
          .name {
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 4px;
          }
          .date {
            font-size: 11px;
          }
        }
        .credit,
        .debit,
        .balance {
          flex: 12%;
          max-width: 12%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          .expand {
            display: none;
          }
          &.true {
            text-decoration: underline;
            .value {
              display: none;
            }
            .expand {
              cursor: pointer;
              display: block;
              position: relative;
              &::after {
                background: white;
                content: "The Value Is Very Small. Please Click This Button To Get The Full Details";
                position: absolute;
                font-size: 13px;
                top: 110%;
                right: -65px;
                left: -65px;
                border: 1px solid $vault-border-color;
                padding: 10px;
                font-weight: normal;
                color: $primary-color;
                display: none;
              }
              &::before {
                background: white;
                content: "";
                position: absolute;
                top: 110%;
                left: 0;
                right: 0;
                margin: auto;
                transform: rotate(45deg) translateY(-25%) translateX(-50%);
                height: 10px;
                width: 10px;
                border: 1px solid $vault-border-color;
                display: none;
              }
              &:hover {
                &::before,
                &::after {
                  display: flex;
                }
              }
            }
          }
          &.main {
            font-weight: 600;
          }
          &.sub {
            opacity: 0.3;
          }
        }
        .credit {
          color: $primary-green;
        }
        .debit {
          color: $primary-red;
        }
        .balance {
          color: $primary-color;
          font-weight: 600;
        }
        &:hover {
          background: #f8f9fa;
        }
      }
    }
  }
  &.mobile-content {
    padding: 0 15px;
    overflow-y: auto;
    display: unset;
    .head {
      height: unset;
      min-height: 60px;
      flex-wrap: wrap;
      margin-bottom: 15px;
      .vault-n-balance {
        flex: 0 0 100%;
        padding: 16px;
        .vault {
          font-size: 20px;
        }
        .balance {
          font-size: 38px;
          small {
            font-size: 24px;
          }
        }
      }
      .coin-select {
        margin: 0;
        .coin-wrap {
          height: 40px;
          width: 40px;
          img {
            width: 25px;
            height: 25px;
          }
        }
        .search-wrapper {
          height: 40px;
          width: 40px;
          .serch-n-result {
            min-height: 40px;
            .search-icon {
              margin: 10px;
              width: 20px;
              height: 20px;
            }
            .search {
              height: 36px;
              input {
                font-size: 16px;
                padding: 0 20px;
              }
              .serch-close {
                font-size: 30px;
              }
            }
            .search-res {
              .search-coin {
                width: 20px;
                height: 20px;
              }
              .coin,
              .value {
                font-size: 14px;
                font-weight: 600;
              }
              .coin {
                margin-left: 6px;
              }
              .value {
                margin-left: auto;
              }
              .change {
                margin-left: 3px;
              }
            }
          }
          &.true {
            width: 100%;
            .serch-n-result {
              width: 350px;
            }
          }
        }
      }
    }
    .controlls {
      height: unset;
      min-height: 40px;
      display: flex;
      flex-direction: column;
      padding: 0 15px;
      .drop-select,
      .search {
        width: 100%;
        padding: 0 20px;
        margin-bottom: 10px;
        .content {
          &.transaction {
            width: 100%;
          }
          &.direction {
            width: 100%;
          }
        }
      }
      .search {
        input {
          width: 100%;
        }
      }
    }
    .assetApps {
      margin-top: 20px;
      width: 100%;
      flex: 0 0 50px;
      height: 50px !important;
      .view {
        display: flex;
        padding: 4px 15px;
        .appIcon {
          min-width: 50px;
          max-width: 50px;
        }
      }
    }
    .vaults-list {
      margin-top: 30px;
      height: unset !important;
      .vaultsView {
        position: relative !important;
        overflow: unset !important;
        .day {
          margin-top: 1rem;
          font-size: 16px;
          line-height: 1;
          padding: 6px 0;
          font-weight: 600;
        }
        .vaults-itm {
          flex-wrap: wrap;
          margin-bottom: 20px;
          img {
            width: 30px;
            height: 30px;
            max-width: 10%;
            flex: 0 0 10%;
            margin: auto 0;
          }
          .name-n-date {
            flex: 0 0 90%;
            height: unset;
            padding: 0 10px;
          }
          .credit,
          .debit,
          .balance {
            flex: 33.33%;
            max-width: 33.33%;
            font-size: 15px;
          }
        }
      }
    }
  }
  &.true {
    .head,
    .vaults-list {
      opacity: 0.25;
    }
    .controlls {
      .search {
        opacity: 0.25;
      }
      .drop-select {
        &.liquid,
        &.bond {
          opacity: 0.25;
        }
      }
    }
  }
}
