.icedIndex .icedContainer .mainContent .content-section.bridge {
  padding: 0;
  .iceTrust {
    padding: 50px 90px 0 90px;
    flex: 1;
    display: flex;
    flex-direction: column;
    .heading {
      display: flex;
      font-size: 50px;
      color: $primary-color;
      font-weight: 600;
      align-items: center;
      line-height: 1;
      img {
        height: 47px;
        width: auto;
        margin-right: 7px;
      }
    }
    .iceDesc {
      margin-top: 15px;
      margin-bottom: 45px;
      font-size: 18px;
      line-height: 1.9;
    }
    .sectionsWrapper {
      .sectionsList {
        .selectionItem {
          border: 1px solid $border-color;
          padding: 30px;
          margin-bottom: 20px;
          .titleSection {
            display: flex;
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 20px;
            justify-content: space-between;
            svg {
              cursor: pointer;
            }
          }
          .text-base,
          .text-more {
            line-height: 1.8;
            .read-more {
              cursor: pointer;
            }
          }
          .text-more,
          .whatBridgeSupport {
            display: none;
          }
          .whatBridgeSupport {
            height: 550px;
            flex: 1;
            flex-direction: column;
            .listsContainer {
              margin-top: 10px;
              flex: 1;
              display: flex;
              justify-content: space-between;
              .scrollList {
                flex: 0 0 42%;
                .scrollListView {
                  display: flex;
                  flex-direction: column;
                  padding: 20px 5px;
                  .head {
                    font-size: 18px;
                    font-weight: 500;
                  }
                  .marketCard {
                    margin-top: 40px;
                    flex: 0 0 20%;
                    border-radius: 8px;
                    background: #ffffff;
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 22px;
                    font-weight: 600;
                    img {
                      height: 30px;
                      margin-right: 10px;
                    }
                  }
                }
              }
            }
          }
          &.true {
            .text-base,
            .text-more {
              display: block;
              .read-more {
                display: none;
              }
            }
            .whatBridgeSupport {
              display: flex;
            }
          }
        }
      }
    }
  }
  .bridge-footer {
    flex: unset;
    min-height: 70px;
    font-weight: 600;
    font-size: 26px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .trust-footer {
    font-size: 20px;
    min-height: 60px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
  }
  .iceTrustMobile {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 40px;
    .iceTrustMobileStep {
      flex: 1;
      display: flex;
      flex-direction: column;
      h3 {
        font-size: 40px;
        font-weight: 600;
        line-height: 1;
        margin-bottom: 4px;
      }
      h5 {
        font-size: 20px;
        line-height: 1;
      }
      h6 {
        font-size: 18px;
        font-weight: normal;
      }
      p {
        font-size: 13px;
        margin: 20px 0;
      }
      .btnLearnMore {
        margin-top: 20px;
        cursor: pointer;
        width: 140px;
        height: 40px;
        background: $primary-color;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: 600;
      }
      .scrollList {
        flex: 1;
        .scrollListView {
          display: flex;
          flex-direction: column;
          padding: 20px 0;
          .head {
            margin-top: 30px;
            font-size: 18px;
            font-weight: 600;
            &:first-child {
              margin-top: 0;
            }
          }
          .marketCard {
            margin-top: 24px;
            flex: 0 0 18%;
            border-radius: 5px;
            background: #ffffff;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-weight: 600;
            img {
              height: 26px;
              margin-right: 10px;
            }
          }
          .btnSetup {
            margin-top: 45px;
            width: 70%;
            background: $primary-color;
            color: white;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 0 0 16%;
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
    }
  }
}
