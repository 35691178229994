.whatIsAssets {
  flex: 1;
  display: flex;
  .posts {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 75px 70px 40px 70px;
    max-width: 90%;
    .head {
      max-height: 65px;
      width: 70%;
      svg {
        width: auto;
        height: 100%;
      }
    }
    .searchWrapper {
      position: relative;
      height: 55px;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      display: flex;
      align-items: center;
      padding: 0 40px;
      margin-top: 50px;
      width: 75%;
      input {
        padding: 0 10px;
        height: 100%;
        width: 0;
        flex: 1;
        border: none;
        background: none;
      }
      img {
        height: 40%;
        width: auto;
        margin-left: 15px;
      }
    }
    .subText {
      margin-top: 40px;
      font-size: 15px;
      line-height: 2;
    }
    .scrlWrap {
      height: 0 !important;
      flex: 1;
      cursor: pointer;
      .postList {
        padding: 1px;
        padding-top: 30px;
        .postItm {
          min-height: 180px;
          width: 100%;
          border: 1px solid $vault-border-color;
          margin-bottom: 20px;
          display: flex;
          .img {
            height: 180px;
            border: 1px solid $vault-border-color;
            width: fit-content;
            position: relative;
            .ph {
              height: 100%;
              width: auto;
            }
            .videoThumb {
              position: absolute;
              top: 0;
              bottom: 0;
              right: 0;
              left: 0;
            }
          }
          .texts {
            padding: 30px;
            .titlePost {
              width: 95%;
              line-height: 1.3;
              font-size: 22px;
              font-weight: 600;
            }
            .descPost {
              width: 95%;
              margin-top: 10px;
              margin-bottom: 0;
              font-size: 16px;
              line-height: 1.6;
            }
          }
          &:hover {
            background: whitesmoke;
            transform: scale(1.01);
          }
        }
      }
    }
  }
  .playlists {
    border-left: 1px solid $vault-border-color;
    flex: 0 0 36%;
    display: flex;
    flex-direction: column;
    padding: 70px 30px 40px 30px;
    .headTitle {
      color: $primary-color;
      line-height: 1;
      font-size: 50px;
      font-weight: 600;
    }
    .scrlWrap {
      height: 0 !important;
      flex: 1;
      margin-top: 30px;
      .playList {
        padding: 1px;
        .helpCard {
          min-height: 350px;
          position: relative;
          border: 1px solid $vault-border-color;
          display: flex;
          margin-bottom: 20px;
          > .front,
          > .back {
            display: flex;
            transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
            transition-duration: 0.5s;
            transition-property: transform, opacity;
            flex: 1;
          }
          > .front {
            transform: rotateY(0deg);
            background-color: white;
            justify-content: center;
            align-items: center;
            h5 {
              font-size: 28px;
              font-weight: 600;
            }
          }
          > .back {
            position: absolute;
            opacity: 0;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            transform: rotateX(-180deg);
            background-color: $primary-color;
            flex-direction: column;
            padding: 40px 60px;
            h5,
            p {
              color: white;
            }
            h5 {
              font-size: 24px;
            }
            p {
              margin-top: 10px;
              line-height: 1.8;
              font-size: 14px;
            }
            .btnLetsGo {
              background-color: white;
              color: $primary-color;
              padding: 6px 20px;
              margin-right: auto;
              font-weight: 600;
              cursor: pointer;
            }
          }
          &:hover {
            > .front {
              transform: rotateX(180deg);
            }
            > .back {
              opacity: 1;
              transform: rotateX(0deg);
            }
          }
          &.disable {
            border-color: $primary-color;
            &:hover {
              > .front {
                transform: rotateX(0deg);
              }
            }
          }
        }
      }
    }
  }
  &.mobile-content {
    flex-direction: column;
    .posts {
      max-width: 100%;
      padding: 30px;
      .head {
        width: 85%;
      }
      .searchWrapper {
        margin-top: 30px;
        width: 100%;
        padding: 0 20px;
      }
      .subText {
        margin-top: 30px;
      }
      .scrlWrap {
        height: unset !important;
        flex: 1;
        .postList {
          position: relative !important;
          .postItm {
            flex-direction: column;
            height: fit-content;
            .img {
              width: 100%;
              border: 1px solid $vault-border-color;
              height: fit-content;
              position: relative;
              .ph {
                height: auto;
                width: 100%;
              }
            }
          }
        }
      }
    }
    .playlists {
      border-top: 1px solid $vault-border-color;
      flex: unset;
      display: flex;
      flex-direction: column;
      padding: 30px;
      .headTitle {
        font-size: 25px;
      }
      .scrlWrap {
        height: unset;
        flex: 1;
        margin-top: 30px;
        .playList {
          position: relative !important;
          .helpCard {
            min-height: 380px;
            > .front {
              h5 {
                text-align: center;
              }
            }
            > .back {
              padding: 30px;
              h5,
              p {
                color: white;
              }
              h5 {
                font-size: 21px;
              }
              p {
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
}
.playListPage {
  padding: 50px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .title {
    font-size: 34px;
    font-weight: 600;
  }
  .goBack {
    color: $primary-color;
    cursor: pointer;
    font-size: 16px;
    text-decoration: underline;
  }
  .closeIcon {
    top: 65px;
    right: 55px;
    font-size: 34px;
    position: absolute;
  }
  .mainList {
    flex: 1;
    display: flex;
    flex-direction: column;
    .ListAccordin {
      margin-top: 20px;
      border: solid 1px $vault-border-color;
      display: flex;
      flex-direction: column;
      .head {
        height: 55px;
        padding: 0 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 17px;
        line-height: 1;
        color: $primary-color;
        .icon {
          font-size: 24px;
        }
      }
      .analyticsEarn {
        height: 0;
        flex-grow: 1;
        display: none;
        .menu {
          flex: 0 0 25%;
          border-right: 1px solid #e7e7e7;
          position: relative;
          .view {
            padding: 20px 40px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            .menu-itm {
              flex: 0 0 20%;
              cursor: pointer;
              color: #343c5b;
              font-size: 16px;
              margin-left: 10px;
              opacity: 0.5;
              &.true {
                font-size: 22px;
                font-weight: 600;
                margin: 0;
                opacity: 1;
              }
            }
          }
          &.true {
            zoom: 0.9;
          }
        }
        .content {
          display: flex;
          .textContent {
            flex: 1;
            padding: 50px;
            h6 {
              font-size: 22px;
              font-weight: 600;
            }
            .textDetail {
              font-size: 18px;
            }
          }
          .palyerWrapper {
            min-width: 320px;
            flex: 0 0 320px;
            border-left: 1px solid $vault-border-color;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              height: 40%;
            }
          }
        }
      }
      &.true {
        flex: 1;
        margin-bottom: 10px;
        .head {
          background: $primary-color;
          color: white;
          font-weight: 600;
        }
        .analyticsEarn {
          display: flex;
        }
      }
    }
  }
  &.mobile-content {
    padding: 30px;
    .closeIcon {
      top: 15px;
      right: 15px;
      font-size: 20px;
      position: fixed;
    }
    .mainList {
      .ListAccordin {
        .head {
          height: fit-content;
          padding: 25px;
          svg {
            margin-left: 10px;
          }
        }
        .analyticsEarn {
          height: fit-content;
          flex-direction: column;
          .menu {
            flex: unset;
            .view {
              position: relative !important;
            }
          }
          .content {
            flex-direction: column;
            .textContent {
              padding: 15px;
              h6 {
                font-size: 18px;
              }
              .textDetail {
                font-size: 15px;
              }
            }
            .palyerWrapper {
              flex: unset;
              min-width: unset;
              height: 150px;
            }
          }
        }
      }
    }
  }
}
