$bg-color: #b2b3c7;
.deposit-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  z-index: 2;
  .overlay-deposit {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.77);
  }
  .select-vault {
    margin: 120px 400px;
    background-color: white;
    flex: 1;
    z-index: 3;
    display: flex;
    flex-direction: column;
    .head {
      height: 90px;
      background: $primary-color;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 26px;
      font-weight: 600;
      margin: 0;
    }
    .content {
      flex: 1;
      padding: 50px 0 50px 70px;
      display: flex;
      flex-direction: column;
      .selectAppHead {
        display: flex;
        line-height: 1;
        align-items: center;
        margin-right: 70px;
        .titleAppSelect {
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: 26px;
          font-weight: 600;
          .subText {
            font-size: 15px;
            font-weight: normal;
          }
        }
        .searcWraper {
          height: 45px;
          margin: 0;
          margin-left: auto;
          border: 1px solid $vault-border-color;
          padding: 0 12px;
          border-radius: 6px;
          display: flex;
          align-items: center;
          input {
            height: 100%;
            border: none;
            background: none;
          }
        }
      }
      .scrollWrap {
        margin-top: 50px;
        height: 0 !important;
        width: unset !important;
        flex: 1;
        .gridApps {
          padding: 0;
          padding-top: 2px;
          .appGrid {
            position: relative;
            display: inline-block;
            height: 0;
            width: 9%;
            padding-top: 9%;
            margin-right: 7.6%;
            margin-bottom: 7.6%;
            height: auto;
            font-size: 20px;
            color: $primary-color;
            font-weight: 600;
            text-align: center;
            box-shadow: 2px 2px 5px 0px rgba(50, 50, 50, 0.75);
            border-radius: 10px;
            background: white;
            overflow: hidden;
            img {
              cursor: pointer;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              height: 100%;
              width: 100%;
            }
          }
        }
      }
      &.detail {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .label {
          font-size: 20px;
          margin-bottom: 0.5rem;
          display: flex;
          justify-content: space-between;
          .btns {
            display: flex;
            .btnPercent {
              border-radius: 6px;
              cursor: pointer;
              padding: 4px;
              width: 70px;
              border: 1px solid $border-color;
              margin-left: 10px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
        .asset-item {
          flex: 0 0 30%;
          max-height: 30%;
          border: 1px solid $vault-border-color;
          margin-bottom: 15px;
          padding: 0 60px;
          display: flex;
          align-items: center;
          cursor: auto;
          .icon {
            height: 40px;
            width: 60px;
            object-fit: contain;
          }
          .name {
            flex: 0 0 30%;
            margin-left: 15px;
            font-size: 22px;
            font-weight: 600;
          }
          .amount {
            flex: 1;
            font-size: 22px;
            font-weight: 600;
            margin: 0;
            margin-left: auto;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            input {
              line-height: 1;
              border: none;
              background: none;
              text-align: right;
            }
            small {
              margin-top: auto;
              font-size: 16px;
            }
          }
        }
        .buttons {
          margin-left: auto;
          display: flex;
          .deposit {
            cursor: pointer;
            border-radius: 3px;
            border: 1px solid $primary-color;
            margin-left: 12px;
            width: 130px;
            height: 40px;
            background: $primary-color;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            color: white;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
            &:hover {
              background: white;
              color: $primary-color;
            }
            &.inv {
              background: white;
              color: $primary-color;
              &:hover {
                background: $primary-color;
                color: white;
              }
            }
          }
        }
      }
    }
    .footer {
      height: 65px;
      background: $primary-color;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      .btnClickHere {
        font-weight: 600;
        text-decoration: underline;
        margin-left: 6px;
      }
    }
    &.coin {
      .content {
        padding: 50px 70px;
        .selectAppHead {
          margin-right: 0;
        }
        .coinListWrap {
          margin-top: 30px;
          height: 0 !important;
          width: unset !important;
          flex: 1;
          .coinList {
            padding: 0 1px;
            display: flex;
            flex-direction: column;
            .asset-item {
              flex: 0 0 30%;
              max-height: 30%;
              border: 1px solid $vault-border-color;
              margin-bottom: 15px;
              padding: 0 60px;
              display: flex;
              align-items: center;
              cursor: auto;
              .icon {
                height: 40px;
                width: 60px;
                object-fit: contain;
              }
              .name {
                flex: 0 0 30%;
                margin-left: 15px;
                font-size: 22px;
                font-weight: 600;
              }
              .amount {
                flex: 0 0 20%;
                font-size: 22px;
                font-weight: 600;
                margin-right: auto;
                small {
                  font-size: 14px;
                }
              }
              .deposit {
                cursor: pointer;
                border: 1px solid $primary-color;
                margin-left: 12px;
                width: 130px;
                height: 40px;
                background: $primary-color;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 600;
                color: white;
                &:hover {
                  background: white;
                  color: $primary-color;
                }
                &.inv {
                  background: white;
                  color: $primary-color;
                  &:hover {
                    background: $primary-color;
                    color: white;
                  }
                }
              }
            }
          }
        }
      }
      .footer {
        padding: 0 70px;
        height: 70px;
        background: $primary-color;
        color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        .label {
          font-size: 21px;
        }
        .value {
          font-size: 23px;
          small {
            font-size: 17px;
          }
        }
      }
    }
  }
  .deposit-card {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 90%;
    max-width: 500px;
    // max-height: 500px;
    height: fit-content;
    min-height: 500px;
    border-radius: 6px;
    box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.55);
    overflow: hidden;
    margin: auto;
    background-color: white;
    flex-direction: column;
    display: flex;
    .title {
      cursor: default;
      border-bottom: 1px solid $primary-color;
      color: $primary-color;
      text-align: center;
      font-weight: 600;
      font-size: 1.7rem;
      line-height: 1;
      margin: 0;
      padding: 15px 0;
    }
    .select-coin {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      .search-wrap {
        border: 1px solid $primary-color;
        color: $primary-color;
        font-size: 16px;
        border-radius: 4px;
        i {
          padding-left: 8px;
        }
        .search-input {
          width: 0;
          flex-grow: 1;
          padding: 4px 8px;
          border: none;
          background: none;
          outline: none;
        }
      }
      .tab-coins {
        border: 1px solid $primary-color;
        text-align: center;
        color: $primary-color;
        overflow: hidden;
        color: white;
        .tab-itm {
          cursor: pointer;
          padding: 8px;
          background-color: $primary-color;
          opacity: 0.8;
          &:hover {
            font-weight: 600;
          }
          &.active {
            opacity: 1;
            font-weight: 600;
          }
        }
      }
      .asset-list {
        overflow-y: auto;
        height: 0;
        flex-grow: 1;
        // max-height: 350px;
        overflow-y: auto;
        .asset-item {
          cursor: pointer;
          border-bottom: 1px solid $primary-color;
          .icon {
            width: 28px;
            height: 28px;
          }
          h4 {
            font-size: 17px;
            line-height: 1;
            color: $primary-color;
            margin-top: auto;
            margin-bottom: auto;
            span {
              &.up {
                color: #26a69a;
              }
              &.down {
                color: #ef5350;
              }
            }
          }
          &.active,
          &:hover {
            h4 {
              color: black;
              font-weight: 600;
            }
          }
        }
      }
      .deposit-footer {
        cursor: pointer;
        background-color: $primary-color;
        color: white;
        font-size: 16px;
        text-align: center;
        line-height: 1;
        padding: 12px;
        margin: 0;
        margin-top: auto;
      }
    }
    .set-amount {
      flex-grow: 1;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      .group {
        p {
          color: $primary-color;
        }
        .border-wrap {
          cursor: default;
          border: 1px solid $primary-color;
          color: $primary-color;
          padding: 6px;
          border-radius: 4px;
          height: 60px;
          // To Remove The Buttons
          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          input[type="number"] {
            -moz-appearance: textfield;
          }
          .amount-input {
            border-color: $primary-color !important;
            width: 0;
            flex-grow: 1;
            padding-left: 8px;
            padding-right: 8px;
            font-size: 20px;
            border: none;
            background: none;
            outline: none;
          }
          .icon {
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 10px;
            margin-right: 10px;
            width: 30px;
            height: 30px;
          }
          h4 {
            color: $primary-color;
            margin: 0;
            font-size: 22px;
            span {
              text-transform: capitalize;
            }
          }
          h6 {
            color: $primary-color;
            margin: 0;
            font-size: 14px;
          }
          &.select-sym {
            height: unset;
            min-height: 60px;
            padding: 0;
            .btnPercent {
              cursor: pointer;
              border: 1px solid $vault-border-color;
              margin: 6px 3px;
              width: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 12px;
              &:hover {
                color: $primary-color;
                font-weight: 500;
              }
            }
            .drop-togle {
              min-height: 60px;
              padding: 6px;
              .fas {
                cursor: pointer;
              }
            }
            .drop-items {
              border-top: 1px solid $primary-color;
              height: 60px;
              padding: 6px;
              justify-content: space-around;
              i {
                font-size: 2rem;
                margin-left: auto;
                margin-right: auto;
              }
              h6 {
                text-align: center;
                margin: 4px;
                margin-left: auto;
                margin-right: auto;
              }
              div {
                cursor: pointer;
              }
            }
          }
        }
      }
      .coin-detail {
        h5 {
          font-size: 18px;
          color: $primary-color;
          margin: 0;
          line-height: 1;
          font-weight: 500;
        }
        h6 {
          font-size: 14px;
          color: $primary-color;
          margin: 0;
          line-height: 1;
          font-weight: 500;
        }
      }
      .deposit-footer {
        cursor: pointer;
        background-color: $primary-color;
        color: white;
        font-size: 16px;
        text-align: center;
        line-height: 1;
        padding: 10px;
        margin: 0;
        margin-top: auto;
      }
    }
    .succes-page {
      flex-grow: 1;
      flex-direction: column;
      display: flex;
      h2 {
        margin: 25px 0;
        text-align: center;
        font-weight: 600;
        color: $primary-color;
      }
      p {
        margin: 0 auto;
        margin-bottom: 10px;
        width: 70%;
        text-align: center;
        font-size: 20px;
        color: $primary-color;
      }
      h6 {
        margin: 0 auto;
        width: 70%;
        text-align: center;
        font-weight: 600;
        font-size: 24px;
        color: $primary-color;
      }

      #slider {
        margin: 10px 0;
        flex-grow: 1;
        height: 100%;
        position: relative;
        perspective: 1000px;
        transform-style: preserve-3d;

        label {
          margin: auto;
          width: 50%;
          height: 100%;
          border-radius: 4px;
          position: absolute;
          left: 0;
          right: 0;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          transition: transform 0.4s ease;
          box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
          background: white;
          .logo-wrapper {
            height: 200px;
            width: 100%;
            display: flex;
          }
          .lottery-card {
            cursor: default;
            display: flex;
            flex-direction: column;
            border: 2px solid $primary-color;
            margin-right: 20px;
            height: 100%;
            min-width: 100%;
            .head-img {
              height: 30px;
              img {
                margin: auto;
                height: 20px;
              }
            }
            .jackpot {
              flex-grow: 1;
              background-color: $primary-color;
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding-top: 10px;
              padding-bottom: 10px;
              h4 {
                margin: 0;
                color: white;
                font-size: 14px;
              }
              h2 {
                margin: 0;
                color: white;
                font-size: 20px;
                font-weight: 600;
              }
            }
            .potential {
              background-color: $primary-color;
              display: flex;
              flex-direction: column;
              h4 {
                margin: auto;
                color: white;
                font-size: 2rem;
              }
            }
            .time {
              height: 50px;
              .unit {
                width: 20%;
                height: 50px;
                border-bottom: 2px solid $primary-color;
                border-left: 2px solid $primary-color;
                display: flex;
                flex-direction: column;
                &:first-child {
                  border-left: none;
                }
                h3 {
                  font-size: 22px;
                  color: $primary-color;
                  font-weight: 600;
                  margin: auto auto 0 auto;
                  line-height: 1;
                }
                h5 {
                  font-size: 10px;
                  color: $primary-color;
                  font-weight: 500;
                  margin: 0 auto auto auto;
                  line-height: 1;
                }
              }
            }
            .foot {
              height: 40px;
              .rate {
                height: 100%;
                width: 60%;
                display: flex;
                h5 {
                  margin: auto;
                  font-weight: 600;
                  font-size: 15px;
                }
              }
              .buy {
                cursor: pointer;
                width: 40%;
                display: flex;
                justify-content: space-around;
                border-left: 2px solid $primary-color;
                h5 {
                  font-weight: 500;
                  font-size: 17px;
                }
                img {
                  width: 15px;
                }
                &:hover,
                &.active {
                  background-color: $primary-color;
                  h5 {
                    color: white;
                  }
                  img {
                    filter: brightness(0) invert(1);
                  }
                }
              }
            }
          }
        }

        .left {
          transform: translate3d(50%, 0, -300px);
        }

        .right {
          transform: translate3d(-50%, 0, -300px);
        }

        .active {
          transform: translate3d(0, 0, 0);
        }
        .inactive {
          transform: translate3d(0, 0, -500px);
        }
      }
    }
  }
  .deposit-card-qr {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 90%;
    max-width: 500px;
    max-height: 500px;
    min-height: 500px;
    border-radius: 6px;
    box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.55);
    overflow: hidden;
    margin: auto;
    background-color: white;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      cursor: default;
      border-bottom: 1px solid $primary-color;
      color: $primary-color;
      text-align: center;
      font-weight: 600;
      font-size: 1.7rem;
      line-height: 1;
      margin: 0;
      padding: 15px 0;
      width: 100%;
    }
    .qr-svg {
      width: 50%;
      height: auto;
    }
    .wallet-addr {
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid $vault-border-color;
      margin-bottom: 50px;
      padding: 6px;
      input {
        cursor: pointer;
        border: none;
        background: none;
      }
    }
  }
}
